<script>
import {appNavBar, sidebarNav} from "@utils"
import 'metismenu/src/metisMenu.scss'
import AkNavbarRoutes from '@components/general/AkNavBarRoutes.vue'
import roleMixin from "@mixins/roleMixin";

export default {
  components: {AkNavbarRoutes},
  mixins: [roleMixin],
  data() {
    return {
      persistentNavRoutes: {
        name: 'home',
        title: this.$t('home'),
        icon: 'ti-view-grid'
      },
      fileRoutes: {
        name: 'files',
        title: "Fiches",
        icon: 'ti-files',
        children: [
          {
            name: 'forecastList',
            title: this.$t('forecast_sheet.title'),
          },
          {
            name: 'linkList',
            title: this.$t('link_sheet.title'),
          },
          {
            name: 'interventionList',
            title: this.$t('interventions'),
          },
        ]
      },
      adminRoutes: {
        name: 'admin',
        title: this.$t('administration'),
        icon: 'ti-settings',
        children: [
          {
            name: 'agerList',
            title: this.$t('ager_label'),
          },
          {
            name: 'uerList',
            title: this.$t('uer_label'),
          },
          {
            name: 'ceiList',
            title: this.$t('cei_label'),
          },
          {
            name: 'circuitList',
            title: this.$t('circuits_label'),
          },
          {
            name: 'userGroupList',
            title: this.$t('user_groups'),
          },
          {
            name: 'userList',
            title: this.$t('users'),
          },
          {
            name: 'axeInterventionList',
            title: this.$t('axeintervention.names'),
          },
        ]
      },
      ceiAgentadminRoutes: {
        name: 'ceiAgentAdmin',
        title: this.$t('administration'),
        icon: 'ti-settings',
        children: [
          {
            name: 'ceiAgentList',
            title: this.$t('ceiAgentManagment'),
          }
        ]
      },
      summaryRoutes: {
        name: 'summaries',
        title: "Synthèses",
        icon: 'ti-bar-chart',
        children: [
          {
            name: 'classicDailySummaryList',
            title:  this.$t('classic_daily_overview_label'),
          },
          {
            name: 'modernizedDailySummaryList',
            title:  this.$t('modernized_daily_overview_label'),
          },
          {
            name: 'weeklySummaryList',
            title:  this.$t('weekly_overview_label'),
          },
        ]
      },
      resultRoutes: {
        name: 'results',
        title: this.$t('vh_reviews'),
        icon: 'ti-blackboard',
        children: [
          {
            name: 'bilanCondTravailSynthese',
            title: this.$t('healthcare_review'),
          },
          {
            name: 'bilanMaterielSynthese',
            title: this.$t('materiel_review'),
          },
          {
            name: 'bilanCircuitSynthese',
            title: this.$t('circuit_review'),
          },
          {
            name: 'bilanCriseSynthese',
            title: this.$t('crisis_review'),
          },
          {
            name: 'bilanFormationSynthese',
            title: this.$t('formation_review'),
          },
        ]
      }
    }
  },
  computed: {},
  mounted() {
    sidebarNav()
    appNavBar()
  }
}
</script>

<template>
  <aside class="app-navbar">
    <div class="navbar-header align-items-center d-lg-block d-none">
      <RouterLink class="navbar-brand" :to="{name : 'home'}">
        <img alt="logo" class="img-fluid logo-desktop" src="@assets/logo_idf.svg"/>
        <img alt="logo" class="img-fluid logo-mobile" src="@assets/logo_idf.svg"/>
      </RouterLink>
    </div>
    <div class="sidebar-nav scrollbar scroll_light">
      <ul id="sidebarNav" class="metismenu">
        <AkNavbarRoutes :routes="persistentNavRoutes"/>
        <AkNavbarRoutes :style="canViewAtLeastOneSheet()?'':'display:none;'" :routes="fileRoutes"/>
        <AkNavbarRoutes :style="canViewAtLeastOneOverview()?'':'display:none;'" :routes="summaryRoutes"/>
        <AkNavbarRoutes :style="canViewAtLeastOneReview()?'':'display:none;'" :routes="resultRoutes"/>
        <AkNavbarRoutes :style="canViewAdmin()?'':'display:none;'" :routes="adminRoutes"/>
        <AkNavbarRoutes :style="canViewCeiUserAdmin()?'':'display:none;'"  :routes="ceiAgentadminRoutes"/>
      </ul>
    </div>
  </aside>
</template>