<script>

import {appheader} from "@utils";
import AkHeader from "@components/layout/AkHeader";
import AkNavbar from "@components/layout/AkNavbar";

export default {
  components: {AkHeader, AkNavbar},

  mounted() {
    appheader();
  }
}
</script>

<template>
  <div class="app-wrap">
    <div>
      <div class="h-100 d-flex justify-content-center">
        <div class="align-self-center">
        </div>
      </div>
    </div>
    <AkHeader/>
    <div class="app-container">
      <AkNavbar/>
      <div id="main" class="app-main">
        <div class="container-fluid">
          <slot/>
        </div>
      </div>
    </div>
    <footer class="footer">
      <span class="float-right">2024 - DiRIF</span>
    </footer>
  </div>

</template>


