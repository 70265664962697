<script>
import BaseLink from '@components/general/BaseLink.vue'

export default {
  components: {BaseLink},
  props: {
    routes: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getRouteTitle(route) {
      return typeof route.title === 'function' ? route.title() : route.title
    }
  }
}
</script>

<template>
  <li :key="routes.name" :class="($route.meta.menuSelected === routes.name)?'active':''">
    <a v-if="routes.children" class="has-arrow">
      <i :class="routes.icon" class="nav-icon ti"/>
      <span class="nav-title">{{ getRouteTitle(routes) }}</span>
    </a>
    
    <BaseLink v-else :href="routes.href" :icon="routes.icon" :name="routes.name" :to=routes exact-active-class='active'>
      <span class="nav-title">{{ getRouteTitle(routes) }}</span>
    </BaseLink>
    
    <ul v-if="routes.children" aria-expanded="false" class="mm-collapse"
        :class="($route.meta.menuSelected === routes.name || $route.meta.subMenu === routes.name)?'mm-show':''">
      <li v-for="child in routes.children" :key="child.name" >
        <BaseLink :name="child.name" :to=child exact-active-class='active'>
          <span class="nav-title">{{ getRouteTitle(child) }}</span>
        </BaseLink>
      </li>
    </ul>
  </li>
</template>